.config-btn {
    font-weight: normal;
    height: 36px;
    margin: 4px 5px 4px 0;
    // padding: 0 15px;
    font-size: 14px;
}

.green-btn {
    .colored-btn(#7abd2d)
   
}

.lightblue-btn {
    .colored-btn(#44b5ec)
}

.red-btn {
    .colored-btn(#ff0000)
}

.colored-btn(@color: #16c1f3) {
    background-color: @color;
    border: 1px solid @color;
    color: #fff;
    // height: auto;
    
    height: 36px;
    margin: 4px 5px 4px 0;
    // padding: 0 15px;
    font-size: 14px;

    span {
        vertical-align: middle;
    }

    svg {
        vertical-align: middle;
        height: 14px;
        width: 14px;
    }

    &:hover {
        background-color: darken(@color, 20%);
        border-color:  darken(@color, 20%);
        color: #fff;
    }
}

.round-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    padding: 0;
    margin: 0 5px;
    overflow: hidden;
    vertical-align: middle;
    transition: all 0.3s ease;

    border-radius: 50%;

}
.ss-app-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: @ss-header-height;
	background-color: #ddd;

	> div {
		position: relative;
		display: flex;
		align-items: center;
		padding-right: 132px;
		height: 100%;
	}

	.logo {
		position: absolute;
		right: 12px;
		top: 12px;
		width: 120px;
	}

	h2 {
		color: #000;
		margin: 0;
		font-family: Verdana, Tahoma, Arial, "Helvetica Neue", Helvetica,
			Sans-Serif;
		font-style: italic;
		font-size: 25px;
	}

	.btn-menu {
		height: 40px;
		width: 40px;
		margin-left: 12px;
		margin-right: 12px;
	}
}

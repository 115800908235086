.ss-app-primary-heading {
	font-size: 16px;
	line-height: 1.75em;
}

.page-title {
	display: inline-block;
    height: 30px;
    line-height: 30px;
    vertical-align: top;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    color: #344356;
}
.App-header {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.App-body {
  padding: 0 20px;
  line-height: normal;
}

.react-json-view {
  line-height: normal;
  font-size: 13px;
}

.ss-app-main-header {
	position: fixed;
	height: 82px;
	border-bottom: 1px solid @light-gray1;
	height: @main-header-height;
	top: 0;
	left: 0;
	right: 0;
	background-color: @white1;
	z-index: @main-header-zindex;

	> div {
		padding: 12px 16px;
		display: flex;
		width: 100%;
		height: @main-header-height;
		justify-content: space-between;
		align-items: center;

		@media screen and (min-width: @screen-md-max) {
			padding: 12px 36px;
		}
	}

	h1 {
		font-size: 20px;
		line-height: 20px;
		margin: 0;
		display: block;

		img {
			max-width: 100%;
		}

		@media screen and (max-width: @screen-md-max) {
			max-width: 360px;
		}
	}
}

.ss-app-user-top-nav {
	white-space: nowrap;
	margin-right: -8px;

	> * {
		vertical-align: middle;
	}

	@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		width: calc(100% - 360px);
	}
}

.ss-app-user-name {
	font-family: "Montserrat", sans-serif;
	text-align: right;
	width: 50vw;
	max-width: 120px;
	padding-right: 8px;
	white-space: nowrap;

	.ss-app-user-dd {
		vertical-align: middle;
		line-height: 12px;
	}
}

.ss-app-dd-profile {
	min-width: 125px;

	a {
		font-size: 12px;
		color: @black;
		> * {
			vertical-align: middle;
		}
		svg {
			margin-right: 8px;
		}
	}
}

@media screen and (min-width: (@screen-xl + 1)) {
	.ss-app-user-top-nav .ss-app-btn.menu-burger {
		display: none;
	}
}

@media screen and (max-width: @screen-sm-max) {
	.ss-app-user-dd,
	.ss-app-main-header h1,
	.ss-app-user-top-nav .ss-app-btn:not(:last-child) {
		display: none;
	}

	.ss-app-main-header,
	.ss-app-main-header > div {
		height: 52px;
	}
	.ss-app-main-header {
		background: linear-gradient(90deg, #1268b3 30%, #01abec 100%);
	}

	.ss-app-main-header {
		> div {
			display: block;
			padding: 12px;
		}
	}

	.ss-app-user-top-nav {
		display: block;
	}

	.ss-app-user-top-nav .ss-app-btn:last-child {
		float: left;
		padding: 0;
		margin-left: 0 !important;

		svg {
			height: 22px;
			path {
				stroke: @white1;
			}
		}
	}

	.ss-app-user-name {
		font-size: 20px;
		color: @white1;
		line-height: 20px;
		vertical-align: middle;
		font-weight: 600;
	}
}

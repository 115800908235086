body.freeze {
	overflow: hidden;
}

.ss-app-loader {
	position: fixed;
	width: 120px;
	height: 60px;

	&.ss-app-middle-loader {
		left: 50%;
		top: 50%;
		margin-top: -30px;
		margin-left: -60px;
	}

	.ss-app-spin-text {
		margin-top: 12px;
	}
}

.ss-app-body {
	min-height: 100vh;
	transition: 200ms all ease-in;

	body.ss-ss-app & {
		padding-top: @main-header-height;
		padding-left: @main-nav-width;
		//@media screen and (min-width: @screen-xxl-min) {
		//	padding-left: @main-nav-width-collapse;
		//}
	}
}

body.ss-ss-app {
	.ss-app-body {
		@media screen and (max-width: @screen-xl-min) {
			padding-left: 0 !important;
		}

		@media screen and (max-width: @screen-sm-max) {
			padding-top: 52px;
		}
	}

	&.menu-show-full {
		.ss-app-body {
			padding-left: @main-nav-width-collapse;
		}
	}
}

.ss-app-wrap {
	position: relative;
	padding: 32px 40px;
	min-height: ~"calc(100vh - @{main-header-height})";
	@media screen and (min-width: @screen-md) and (max-width: @screen-lg-max) {
		padding: 24px;
	}

	@media (max-width: @screen-sm-max) {
		padding: 20px;
	}
}

.ss-app-body-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	> h3 {
		font-size: 26px;
		line-height: 1.25em;
		font-weight: 400;
	}
	@media screen and (max-width: @screen-sm-max) {
		margin: -24px -20px 20px;
		padding: 12px 20px;

		background-color: @white1;
		h3 {
			font-size: 16px;
			font-weight: bold;
			color: @blue;
		}
	}
}

.ss-app-body-header-toolbar {
	.ss-app-btn {
		@media screen and (min-width: @screen-md) {
			min-width: 175px;
			margin-left: 12px;
			box-shadow: 0px 5px 20px rgba(0, 55, 128, 0.2);
		}

		@media screen and (max-width: @screen-sm-max) {
			min-width: 100px;
			margin-left: 8px;
			padding: 0 16px;
		}
	}
}

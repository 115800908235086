.ss-app-dashboard-links {
	position: fixed;
	box-sizing: border-box;
	left: 0;
	bottom: 0;
	top: @ss-header-height;
	background-color: #23588a;
	width: @ss-navlink-width;
	font-family: Verdana, Tahoma, Arial, "Helvetica Neue", Helvetica, Sans-Serif;

	> div {
		height: 100%;
	}

	&.ss-app-nav-links-hide {
		left: -@ss-navlink-width;
	}

	.nav-link-item {
		color: #fff;
		text-decoration: none;
		display: block;
		padding: 5px 12px;

		span {
			font-size: 12px;
		}

		.icon {
			font-family: ui-font-icons;
			font-style: normal;
			font-weight: 400;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			width: 31px;
			line-height: 32px;
			font-size: 20px;
			color: #fff !important;
			text-align: center;
			vertical-align: middle;
			padding: 0;
		}
	}
}

.ss-app-navigator {
	position: fixed;
	left: 0;
	top: @main-header-height;
	bottom: 0;
	background-color: @blue;
	background: linear-gradient(180deg, #1268b3 30%, #01abec 100%);
	width: @main-nav-width;
	z-index: @main-menu-zindex;
	transition: 200ms all ease-out;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	body.menu-show-full & {
		.ss-app-nav-item {
			overflow: inherit;
			.ss-app-nav-item-label {
				opacity: 1;
				margin-left: 0;
				width: auto;
			}

			.ss-app-sub-link-arrow {
				width: 16px;
				height: 16px;
			}
		}
	}

	//@media screen and (min-width: @screen-xxl-min) {
	//	width: @main-nav-width-collapse;
	//	.ss-app-nav-item {
	//		overflow: inherit;
	//		.ss-app-nav-item-label {
	//			opacity: 1;
	//			margin-left: 0;
	//			width: auto;
	//		}
	//	}
	//}

	@media screen and (min-width: @screen-lg) {
		.ss-app-navigator-close {
			display: none;
		}

		body.menu-show-full & {
			width: @main-nav-width-collapse;
		}
	}

	@media screen and (min-width: @screen-md) {
		.ss-app-navigator-close,
		.ss-app-logout-btn {
			display: none;
		}
	}

	@media screen and (max-width: @screen-xl-min) {
		width: @main-nav-width-collapse;
		transform: translateX(-@main-nav-width-collapse - 2);

		body.menu-show-full & {
			transform: translateX(0);
			z-index: 999;
		}
	}

	@media screen and (max-width: @screen-sm-max) {
		width: 100vw;
		transform: translateX(-100vw);
		top: 0;
		background: linear-gradient(
			348.86deg,
			#f0f4fd -56.86%,
			#ffffff 126.39%
		);

		.ss-app-navigator-close {
			position: absolute;
			top: 12px;
			right: 12px;
			padding: 0;
			width: 36px;
			height: 36px;
			line-height: 36px;
			text-align: center;
		}

		&::before {
			display: block;
			content: "Menu";
			font-size: 24px;
			font-weight: 600;
			padding: 12px 24px 0;
		}
	}
}

.ss-app-navigator-wrapper {
	position: relative;
	height: 100%;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden;

	@media screen and (max-width: @screen-sm-max) {
		height: calc(100vh - 120px);
		overflow-y: auto;
		padding: 16px 0;
		overflow-x: hidden;

		> div:not(.ss-app-logout-btn):not(.ss-app-collapse-btn) {
			border-top: 1px solid @border-color-base;
			&:first-child {
				border-top: 0;
			}
		}
	}

	.link-item-wrapper {
		padding-left: 16px;
		padding-right: 16px;

		&:last-child {
			margin-bottom: 12px;
		}

		&:first-of-type {
			margin-top: 12px;
		}
	}
}

.ss-app-nav-item {
	position: relative;
	display: block;
	padding: 12px 0 12px 36px;
	font-size: 14px;
	color: @white1;
	white-space: nowrap;
	margin-bottom: 12px;
	line-height: 1.15em;

	svg {
		position: absolute;
		top: 10px;
		left: 4px;

		&.fill-ico {
			fill: @white1;
		}
		&.stroke-ico {
			stroke: @white1;
		}
	}

	.ss-app-sub-link-arrow {
		position: absolute;
		top: 12px;
		right: 0;
		display: inline-block;
		height: 12px;
		width: 12px;

		> svg {
			position: static;
			width: 100%;
			height: 100%;
		}
	}

	@media screen and (max-width: @screen-sm-max) {
		color: @blue;
		svg {
			left: 24px;
			top: 15px;
			&.fill-ico {
				fill: @blue;
			}
			&.stroke-ico {
				stroke: @blue;
			}
		}

		.ss-app-sub-link-arrow {
			top: 18px;
			right: 16px;
			> svg path {
				stroke: @blue;
			}
		}
	}

	@media screen and (max-width: @screen-sm-max) {
		svg:not(.menu-edge) {
			top: 12px;
		}
	}

	.ss-app-nav-item-label {
		transition: all 200ms ease-out;
		opacity: 0;
		font-weight: 700;
		display: inline-block;
		width: 0px;
		margin-left: -24px;
	}

	&:hover {
		color: @white1;
	}

	&.active {
		background-color: @white2;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		margin-right: -16px;
		margin-left: -4px;
		padding-left: 40px;

		svg:not(.menu-edge) {
			left: 8px;
		}

		.ss-dashboard-wrap-nav-sub-links > .ss-dashboard-wrap-nav-item {
			padding-left: 40px;
		}

		svg.fill-ico {
			fill: @blue;
		}
		svg.stroke-ico {
			stroke: @blue;
		}

		.ss-app-nav-item-label {
			color: @blue;
		}

		&:hover {
			text-shadow: none;
		}

		.ss-app-nav-sub-links {
			opacity: 1;
		}

		.ss-app-sub-link-arrow {
			right: 16px;
			transform: rotate(180deg);
			> svg path {
				stroke: @blue;
			}
		}
	}

	@media screen and (max-width: @screen-xl) {
		body.menu-show-full &.active {
			margin-right: 0;
			border-radius: 10px;
		}
	}

	@media screen and (max-width: @screen-sm-max) {
		padding: 16px 24px 16px 60px;
		margin-bottom: 0;

		.ss-app-nav-item-label {
			font-size: 16px;
			font-weight: normal;
		}
	}

	.ss-app-collapse-header {
		margin-bottom: 0 !important;
	}
}

div.ss-app-nav-item {
	transition: height 300ms ease-in-out;
	> div:not(.ss-app-nav-sub-links) {
		cursor: pointer;
	}
}
.ss-app-nav-sub-links {
	position: absolute;
	top: 42px;
	left: 0;
	opacity: 0;

	.ss-app-nav-item {
		margin-bottom: 0;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 40px;

		.ss-app-nav-item-label {
			color: @gray7;
		}

		svg {
			top: 4px;
			&.stroke-ico {
				stroke: #200e32;
			}
		}
	}

	> a:hover .ss-app-nav-item-label {
		color: @black;
	}

	@media screen and (max-width: @screen-sm-max) {
		padding: 12px 0;
	}
}

.ss-app-nav-item-top-edge,
.ss-app-nav-item-bottom-edge {
	position: absolute;
	right: 0;
	width: 10px;
	height: 10px;
	background-repeat: no-repeat;
	z-index: 10;
	opacity: 0;

	.ss-app-nav-item.active & {
		opacity: 1 !important;
	}

	@media screen and (max-width: @screen-xl) {
		display: none;
	}
}

.ss-app-nav-item-top-edge {
	bottom: 100%;
	background-position: -10px -10px;
}

.ss-app-nav-item-bottom-edge {
	top: 100%;
	background-position: -10px 0;
}

.ss-app-collapse-btn,
.ss-app-logout-btn {
	width: 100%;
	padding: 0 16px 12px;
	> a {
		margin-bottom: 0;
	}
}

.ss-app-logout-btn {
	padding: 0;
	border-top: 1px solid @border-color-base;
}

@media screen and (max-width: @screen-sm-max) {
	.ss-app-collapse-btn {
		display: none;
	}
}

@media screen and (max-width: @screen-md-max) {
	body.menu-show-full {
		overflow: hidden;
	}
}

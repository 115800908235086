.edit-grid {
    display: grid;
    grid-template-columns: 140px 300px;
    justify-content: space-between;
    align-items: center;
}

.create-branch-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    max-height: 80%;
    height: 500px;

    .tree-height {
        max-height: 500px;
        margin-right: 40px;
        overflow: auto;
    }
}


.add-branch-grid {
    display: grid;
    grid-template-columns: 140px 300px;
    justify-content: space-between;
    align-items: center;
}

// tree
.ss-app-table-thead {
    .ss-app-table-cell {
        background-color: transparent;
        font-weight: bold;
    }
}

.text-underline {
    text-decoration: underline;
}

.modal-label-inline {
    display: inline-block;
    min-width: 140px;
}

.word-break-word {
    word-break: break-word;
}

.page-title {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
}